import type { PricingTier } from './types';
import { Clock, Zap, Rocket } from 'lucide-react';

export const PRICING_TIERS: Record<string, PricingTier> = {
  Starter: {
    name: 'Starter',
    description: 'Perfect for small projects and quick transcriptions',
    price: {
      oneTime: '30'
    },
    priceIds: {
      oneTime: 'price_1QJiBxAK2CW98JsIiUMv0JbG'
    },
    features: [
      '5 hours of transcription',
      'Export to TXT and SRT formats',
      'Unlimited storage time',
      'Standard processing speed',
      '$6/hour'
    ],
    color: 'from-blue-500 to-blue-600',
    hoverColor: 'hover:from-blue-600 hover:to-blue-700',
    icon: Clock,
    hours: 5
  },
  Pro: {
    name: 'Pro',
    description: 'Most popular choice for content creators',
    price: {
      oneTime: '60'
    },
    priceIds: {
      oneTime: 'price_1QJiC0AK2CW98JsIl7S9rV4T'
    },
    features: [
      '15 hours of transcription',
      'Export to TXT and SRT formats',
      'Unlimited storage time',
      'Priority processing speed',
      '$4/hour (33% savings)'
    ],
    color: 'from-[#81b29a] to-[#6b9580]',
    hoverColor: 'hover:from-[#6b9580] hover:to-[#557969]',
    popular: true,
    icon: Zap,
    hours: 15
  },
  Enterprise: {
    name: 'Enterprise',
    description: 'Best value for high-volume users',
    price: {
      oneTime: '100'
    },
    priceIds: {
      oneTime: 'price_1QJiC2AK2CW98JsIBg0SQPqk'
    },
    features: [
      '30 hours of transcription',
      'Export to TXT and SRT formats',
      'Unlimited storage time',
      'Priority processing speed',
      '$3.33/hour (44% savings)'
    ],
    color: 'from-purple-500 to-purple-600',
    hoverColor: 'hover:from-purple-600 hover:to-purple-700',
    icon: Rocket,
    hours: 30
  }
};