import React from 'react';
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import { Shield } from 'lucide-react';

export const PrivacyPage: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      
      <main className="flex-grow bg-gray-50 pt-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="bg-white rounded-xl shadow-sm p-8">
            <div className="flex items-center gap-4 mb-8">
              <Shield className="h-8 w-8 text-[#81b29a]" />
              <h1 className="text-3xl font-bold text-gray-900">Privacy Policy</h1>
            </div>

            <div className="prose prose-slate max-w-none">
              <p className="text-sm text-gray-500 mb-8">
                Last Updated: April 11, 2021
              </p>

              <div className="space-y-8">
                <section>
                <p className="font-medium">AT FINANCIAL SUCCESS MEDIA, LLC, (“FSM”) WE ARE COMMITTED TO RESPECTING YOUR PRIVACY CHOICES AND BEING TRANSPARENT ABOUT WHAT INFORMATION WE COLLECT FROM YOU AND HOW WE USE THAT INFORMATION. WE DO NOT SHARE, SELL, LICENSE, TRADE, OR READ YOUR PERSONAL INFORMATION TO OTHERS EXCEPT IN THE WAYS EXPLAINED IN THIS PRIVACY POLICY.</p>
                <p>THE PURPOSE OF THIS PRIVACY POLICY IS TO HELP YOU UNDERSTAND</p>
                </section>
                <section>
                <h2 className="text-xl font-semibold text-gray-900">WHAT THE USER AGREES UPON BY USING THE APP</h2>
                <ul className="list-disc pl-6 space-y-2">
                  <li>WHAT PERSONAL DATA (PD) AND NON-PERSONAL DATA (NPD) WE MAY COLLECT FROM YOU</li>
                  <li>HOW WE COLLECT IT</li>
                  <li>HOW WE PROTECT IT</li>
                  <li>HOW WE MAY SHARE IT</li>
                  <li>HOW YOU CAN ACCESS AND CHANGE IT</li>
                  <li>HOW YOU CAN LIMIT OUR SHARING OF IT</li>
                </ul>
                <p>OUR PRIVACY NOTICE ALSO EXPLAINS CERTAIN LEGAL RIGHTS THAT YOU HAVE WITH RESPECT TO YOUR PERSONAL DATA.</p>
                </section>
                <section>
                  <h2 className="text-xl font-semibold text-gray-900">YOUR CONSENT TO THIS PRIVACY POLICY</h2>
                    <p>BY USING THIS WEBSITE YOU CONSENT TO THE TERMS OF THIS PRIVACY POLICY.</p>
                    <h3>YOUR RIGHTS</h3>
                    <p>WHEN USING OUR WEBSITE AND SUBMITTING PERSONAL DATA TO US, YOU MAY HAVE CERTAIN RIGHTS UNDER THE GENERAL DATA PROTECTION REGULATION (GDPR) AND OTHER LAWS. DEPENDING ON THE LEGAL BASIS FOR PROCESSING YOUR PERSONAL DATA, YOU MAY HAVE SOME OR ALL OF THE FOLLOWING RIGHTS:</p>
                    <strong>THE RIGHT TO BE INFORMED</strong>
                    <p>YOU HAVE THE RIGHT TO BE INFORMED ABOUT THE PERSONAL DATA WE COLLECT FROM YOU, AND HOW WE PROCESS IT.</p>
                    <strong>THE RIGHT OF ACCESS</strong>
                    <p>YOU HAVE THE RIGHT TO GET CONFIRMATION THAT YOUR PERSONAL DATA IS BEING PROCESSED AND HAVE THE ABILITY TO ACCESS YOUR PERSONAL DATA.</p>
                    <strong>THE RIGHT TO RECTIFICATION</strong>
                    <p>YOU HAVE THE RIGHT TO HAVE YOUR PERSONAL DATA CORRECTED IF IT IS INACCURATE OR INCOMPLETE.</p>
                    <strong>THE RIGHT TO ERASURE (RIGHT TO BE FORGOTTEN)</strong>
                    <p>YOU HAVE THE RIGHT TO REQUEST THE REMOVAL OR DELETION OF YOUR PERSONAL DATA IF THERE IS NO COMPELLING REASON FOR US TO CONTINUE PROCESSING IT.</p>
                    <strong>THE RIGHT TO RESTRICT PROCESSING</strong>
                    <p>YOU HAVE A RIGHT TO ‘BLOCK’ OR RESTRICT THE PROCESSING OF YOUR PERSONAL DATA. WHEN YOUR PERSONAL DATA IS RESTRICTED, WE ARE PERMITTED TO STORE YOUR DATA, BUT NOT TO PROCESS IT FURTHER.</p>
                    <strong>THE RIGHT TO DATA PORTABILITY</strong>
                    <p>YOU HAVE THE RIGHT TO REQUEST AND GET YOUR PERSONAL DATA THAT YOU PROVIDED TO US AND USE IT FOR YOUR OWN PURPOSES. WE WILL PROVIDE YOUR DATA TO YOU WITHIN 30 DAYS OF YOUR REQUEST. TO REQUEST YOUR PERSONAL DATA, PLEASE CONTACT US USING THE INFORMATION AT THE TOP OF THIS PRIVACY NOTICE.</p>
                    <strong>THE RIGHT TO OBJECT</strong>
                    <p>YOU HAVE THE RIGHT TO OBJECT TO US PROCESSING YOUR PERSONAL DATA FOR THE FOLLOWING REASONS:</p>
                    <p>* PROCESSING WAS BASED ON LEGITIMATE INTERESTS OR THE PERFORMANCE OF A TASK IN THE PUBLIC INTEREST/EXERCISE OF OFFICIAL AUTHORITY (INCLUDING PROFILING);</p>
                    <p>DIRECT MARKETING (INCLUDING PROFILING); AND</p>
                    <p>PROCESSING FOR PURPOSES OF SCIENTIFIC/HISTORICAL RESEARCH AND STATISTICS.</p>
                    <p>* RIGHTS IN RELATION TO AUTOMATED DECISION-MAKING AND PROFILING.</p>
                    <strong>AUTOMATED INDIVIDUAL DECISION-MAKING AND PROFILING</strong>
                    <p>YOU WILL HAVE THE RIGHT NOT TO BE SUBJECT TO A DECISION BASED SOLELY ON AUTOMATED PROCESSING, INCLUDING PROFILING, WHICH PRODUCES LEGAL EFFECTS CONCERNING YOU OR SIMILARLY SIGNIFICANTLY AFFECTS YOU.</p>
                    <strong>FILING A COMPLAINT WITH AUTHORITIES</strong>
                    <p>YOU HAVE THE RIGHT TO FILE A COMPLAINT WITH SUPERVISORY AUTHORITIES IF YOUR INFORMATION HAS NOT BEEN PROCESSED IN COMPLIANCE WITH THE GENERAL DATA PROTECTION REGULATION. IF THE SUPERVISORY AUTHORITIES FAIL TO ADDRESS YOUR COMPLAINT PROPERLY, YOU MAY HAVE THE RIGHT TO A JUDICIAL REMEDY.</p>
                  </section>
                  <section>
                    <h2 className="text-xl font-semibold text-gray-900">WHAT INFORMATION DOES FINANCIAL SUCCESS MEDIA, LLC COLLECT?</h2>
                    <p>WE COLLECT TWO TYPES OF INFORMATION PERSONAL DATA (PD) AND NON-PERSONAL DATA (NPD). GENERALLY SPEAKING, YOU CAN VISIT THIS WEBSITE WITHOUT IDENTIFYING WHO YOU ARE OR REVEALING ANY INFORMATION ABOUT YOURSELF.</p>
                    <p>‘PERSONAL DATA’ (PD) MEANS ANY INFORMATION RELATING TO AN IDENTIFIED OR IDENTIFIABLE NATURAL PERSON (‘DATA SUBJECT’); AN IDENTIFIABLE NATURAL PERSON IS ONE WHO CAN BE IDENTIFIED, DIRECTLY OR INDIRECTLY, BY REFERENCE TO AN IDENTIFIER SUCH AS A NAME, AN IDENTIFICATION NUMBER, LOCATION DATA, AN ONLINE IDENTIFIER OR TO ONE OR MORE FACTORS SPECIFIC TO THE PHYSICAL, PHYSIOLOGICAL, GENETIC, MENTAL, ECONOMIC, CULTURAL OR SOCIAL IDENTITY OF THAT NATURAL PERSON. PD IS IN MANY WAYS THE SAME AS PERSONALLY IDENTIFIABLE INFORMATION (PII). HOWEVER, PD IS BROADER IN SCOPE AND COVERS MORE DATA.
                    </p>
                    <p>‘NON-PERSONAL DATA’ (NPD) IS INFORMATION THAT IS IN NO WAY PERSONALLY IDENTIFIABLE. FOR EXAMPLE, A SITE MAY RECORD THE NUMBER OF VISITS TO A PARTICULAR PAGE THAT OCCUR IN A GIVEN PERIOD OF TIME, BUT THAT DOES NOT NECESSARILY PROVIDE THE NAMES OR OTHER IDENTIFYING INFORMATION OF EACH VISITOR.</p>
                    <p>WHEN YOU SUBSCRIBE TO OUR NEWSLETTER, PLACE AN ORDER FOR OUR PRODUCTS OR SERVICES, OR ENROLL IN ONE OF OUR COURSES, WE COLLECT CERTAIN INFORMATION TO PROCESS YOUR REQUEST FOR INFORMATION AND/OR PRODUCTS/SERVICES.</p>
                    <p>A “VISITOR” IS SOMEONE WHO MERELY BROWSES OUR WEBSITE. A “MEMBER” IS SOMEONE WHO HAS REGISTERED WITH US TO USE OR BUY OUR SERVICES AND PRODUCTS. THE TERM “USER” IS A COLLECTIVE IDENTIFIER THAT REFERS TO EITHER A VISITOR OR A MEMBER.</p>
                  </section>
                  <section>
                    <h3 className="text-lg font-medium mt-4 mb-2">INFORMATION WE COLLECT</h3>
                    <p>GENERALLY, YOU CONTROL THE AMOUNT AND TYPE OF INFORMATION THAT YOU PROVIDE TO US WHEN USING OUR WEBSITE.</p>
                    <p>OUR LEGAL BASIS FOR COLLECTING AND PROCESSING YOUR PD IS BASED ON CONSENT.</p>
                    <p>WHEN YOU ENTER AND USE OUR WEBSITE AND AGREE TO ACCEPT COOKIES, SOME OF THESE COOKIES MAY CONTAIN YOUR PD.</p>
                    <h4>WHAT ARE COOKIES AND HOW ARE THEY USED?</h4>
                    <p>COOKIES ARE SMALL DATA FILES, TYPICALLY MADE UP OF A STRING OF TEXT, THAT ASSIGN YOU A UNIQUE IDENTIFIER. THIS INFORMATION ENABLES OUR WEBSITE TO RECEIVE INFORMATION FROM YOUR COMPUTER TO PROVIDE A MORE TAILORED AND USER-FRIENDLY EXPERIENCE FOR YOU.</p>
                    <p>BY AGREEING TO ACCEPT OUR USE OF COOKIES, YOU ARE GIVING US, AND THIRD PARTIES WE PARTNER WITH, PERMISSION TO PLACE, STORE, AND ACCESS SOME OR ALL THE COOKIES DESCRIBED BELOW ON YOUR COMPUTER.</p>
                    <strong>STRICTLY NECESSARY COOKIES</strong>
                    <p>THESE COOKIES ARE NECESSARY FOR PROPER FUNCTIONING OF THE WEBSITE, SUCH AS DISPLAYING CONTENT, LOGGING IN, VALIDATING YOUR SESSION, RESPONDING TO YOUR REQUEST FOR SERVICES, AND OTHER FUNCTIONS. MOST WEB BROWSERS CAN BE SET TO DISABLE THE USE OF COOKIES. HOWEVER, IF YOU DISABLE THESE COOKIES, YOU MAY NOT BE ABLE TO ACCESS FEATURES ON OUR WEBSITE CORRECTLY OR AT ALL.</p>
                    <strong>PERFORMANCE COOKIES</strong>
                    <p>THESE COOKIES COLLECT INFORMATION ABOUT THE USE OF THE WEBSITE, SUCH AS PAGES VISITED, TRAFFIC SOURCES, USERS’ INTERESTS, CONTENT MANAGEMENT, AND OTHER WEBSITE MEASUREMENTS.</p>
                    <strong>FUNCTIONAL COOKIES</strong>
                    <p>THESE COOKIES ENABLE THE WEBSITE TO REMEMBER A USER’S CHOICES – SUCH AS THEIR LANGUAGE, USER NAME, AND OTHER PERSONAL CHOICES – WHILE USING THE WEBSITE. THEY CAN ALSO BE USED TO DELIVER SERVICES, SUCH AS LETTING A USER MAKE A BLOG POST, LISTEN TO AUDIO, OR WATCH VIDEOS ON THE WEBSITE.</p>
                    <strong>MEDIA COOKIES</strong>
                    <p>THESE COOKIES CAN BE USED TO IMPROVE A WEBSITE’S PERFORMANCE AND PROVIDE SPECIAL FEATURES AND CONTENT. THEY CAN BE PLACED BY THIRD PARTIES WHO PROVIDE SERVICES TO US OR BY OUR COMPANY.</p>
                    <strong>ADVERTISING OR TARGETING COOKIES</strong>
                    <p>THESE COOKIES ARE USUALLY PLACED AND USED BY ADVERTISING COMPANIES TO DEVELOP A PROFILE OF YOUR BROWSING INTERESTS AND SERVE ADVERTISEMENTS ON OTHER WEBSITES THAT ARE RELATED TO YOUR INTERESTS. YOU WILL SEE LESS ADVERTISING IF YOU DISABLE THESE COOKIES.</p>
                    <strong>SESSION COOKIES</strong>
                    <p>THESE COOKIES ALLOW WEBSITES TO LINK THE ACTIONS OF A USER DURING A BROWSER SESSION. THEY MAY BE USED FOR A VARIETY OF PURPOSES, SUCH AS REMEMBERING WHAT A USER HAS PUT IN THEIR SHOPPING CART AS THEY BROWSE A WEBSITE. SESSION COOKIES ALSO PERMIT USERS TO BE RECOGNIZED AS THEY NAVIGATE A WEBSITE SO THAT ANY ITEM OR PAGE CHANGES THEY MAKE ARE REMEMBERED FROM PAGE TO PAGE. SESSION COOKIES EXPIRE AFTER A BROWSER SESSION; THUS, THEY ARE NOT STORED LONG TERM.</p>
                    <strong>PERSISTENT COOKIES</strong>
                    <p>THESE COOKIES ARE STORED ON A USER'S DEVICE IN BETWEEN BROWSER SESSIONS, WHICH ALLOWS THE USER’S PREFERENCES OR ACTIONS ACROSS A SITE (OR, IN SOME CASES, ACROSS DIFFERENT SITES) TO BE REMEMBERED. PERSISTENT COOKIES MAY BE USED FOR A VARIETY OF PURPOSES, INCLUDING REMEMBERING USERS’ CHOICES AND PREFERENCES WHEN USING A WEBSITE OR TO TARGET ADVERTISING TO THEM.</p>
                    <strong>WE MAY ALSO USE COOKIES FOR:</strong>
                    <ul className="list-disc pl-6 space-y-2">
                      <li>IDENTIFYING THE AREAS OF OUR WEBSITE THAT YOU HAVE VISITED</li>
                      <li>PERSONALIZING CONTENT THAT YOU SEE ON OUR WEBSITE</li>
                      <li>OUR WEBSITE ANALYTICS</li>
                      <li>REMARKETING OUR PRODUCTS OR SERVICES TO YOU</li>
                      <li>REMEMBERING YOUR PREFERENCES, SETTINGS, AND LOGIN DETAILS</li>
                      <li>TARGETED ADVERTISING AND SERVING ADS RELEVANT TO YOUR INTERESTS</li>
                      <li>AFFILIATE MARKETING</li>
                      <li>ALLOWING YOU TO POST COMMENTS</li>
                      <li>ALLOWING YOU TO SHARE CONTENT WITH SOCIAL NETWORKS</li>
                    </ul>
                    <p>MOST WEB BROWSERS CAN BE SET TO DISABLE THE USE OF COOKIES. HOWEVER, IF YOU DISABLE COOKIES, YOU MAY NOT BE ABLE TO ACCESS FEATURES ON OUR WEBSITE CORRECTLY OR AT ALL.</p>
                    <p>WE AUTOMATICALLY RECEIVE INFORMATION FROM YOUR WEB BROWSER OR MOBILE DEVICE. THIS INFORMATION INCLUDES THE NAME OF THE WEBSITE FROM WHICH YOU ENTERED OUR WEBSITE, IF ANY, AS WELL AS THE NAME OF THE WEBSITE YOU’LL VISIT WHEN YOU LEAVE OUR WEBSITE. THIS INFORMATION ALSO INCLUDES THE IP ADDRESS OF YOUR COMPUTER/THE PROXY SERVER YOU USE TO ACCESS THE INTERNET, YOUR INTERNET SERVICE PROVIDER’S NAME, YOUR WEB BROWSER TYPE, THE TYPE OF MOBILE DEVICE, YOUR COMPUTER OPERATING SYSTEM, AND DATA ABOUT YOUR BROWSING ACTIVITY WHEN USING OUR WEBSITE. WE USE ALL THIS INFORMATION TO ANALYZE TRENDS AMONG OUR USERS TO HELP IMPROVE OUR WEBSITE.</p>
                  </section>
                  <section>
                    <h3 className="text-lg font-medium mt-4 mb-2">HOW DOES FINANCIAL SUCCESS MEDIA, LLC USE THE INFORMATION WE COLLECT?</h3>
                    <strong>NON-PERSONAL DATA (NPD)</strong>
                    <p>WE MAY USE NON-PERSONALLY IDENTIFIABLE INFORMATION, LIKE DATA ABOUT THE MOST VISITED SITE PAGES OR THE DURATION OF CERTAIN SITE PAGE VISITS THAT ARE NOT TIED TO A SPECIFIC SITE USER, TO MAINTAIN, IMPROVE, AND PROTECT OUR SITE AND OUR PRODUCT AND SERVICE OFFERINGS.</p>
                    <strong>PERSONAL DATA (PD)</strong>
                    <p>WHEN YOU SIGN UP FOR OUR NEWSLETTER, WE USE THE NAME AND EMAIL ADDRESS THAT YOU PROVIDED TO SUBSCRIBE TO OUR NEWSLETTER AND/OR OUR EMAIL LIST. WE WILL SEND YOU PERIODIC NEWSLETTERS AND/OR EMAILS. WE STORE THE NAME AND EMAIL ADDRESS THAT YOU PROVIDED IN AN SSL ENCRYPTED DATABASE.</p>
                    <p>WHEN YOU PURCHASE A DIGITAL PRODUCT OR OUR SERVICES WE USE THE PERSONAL INFORMATION YOU PROVIDE TO PROCESS THE TRANSACTION AND DELIVER YOUR DIGITAL PRODUCT AND/OR SERVICE, AND TO PROVIDE YOU CUSTOMER SERVICE AS NECESSARY. WE STORE THIS INFORMATION IN AN SSL ENCRYPTED DATABASE.</p>
                    <strong>SOCIAL MEDIA WIDGETS</strong>
                    <p>OUR WEBSITE INCLUDES SEVERAL SOCIAL MEDIA FEATURES, INCLUDING FACEBOOK, TWITTER, PINTEREST, GOOGLE+, YOUTUBE, INSTAGRAM, LINKEDIN, ETC. BUTTONS. THESE FEATURES MAY COLLECT YOUR IP ADDRESS, THE PAGES YOU ARE VISITING ON OUR SITE, AND MAY SET A COOKIE TO ENABLE THE FEATURE TO FUNCTION PROPERLY. THESE FEATURES AND WIDGETS ARE EITHER HOSTED BY A THIRD PARTY OR HOSTED DIRECTLY ON OUR SITE. YOUR INTERACTIONS WITH THESE FEATURES AND WIDGETS ARE GOVERNED BY THE PRIVACY POLICY OF THE COMPANY PROVIDING THE FEATURES.</p>
                    <strong>WEBSITE CHAT SOFTWARE</strong>
                    <p>OUR WEBSITE CONTAINS CHAT SOFTWARE THAT ENABLE VISITORS TO COMMUNICATE WITH US LIVE ONLINE OR OFFLINE BY EMAIL. IN SOME CASES, VISITORS CAN COMMUNICATE WITH US WITHOUT BECOMING A MEMBER OR BUYING OUR PRODUCTS AND SERVICES. WHEN YOU USE THIS CHAT SOFTWARE, WE MAY COLLECT SOME OR ALL THE FOLLOWING INFORMATION: YOUR EMAIL ADDRESS, FIRST NAME, LAST NAME, LOCATION, AND ANY OTHER INFORMATION YOU WILLINGLY CHOOSE TO PROVIDE TO US. YOU SHOULD LIMIT THE INFORMATION YOU PROVIDE TO US THAT IS ONLY NECESSARY TO ANSWER YOUR QUESTIONS.</p>
                    <strong>WHAT HAPPENS IF YOU DON’T GIVE US YOUR PD?</strong>
                    <p>IF YOU DO NOT PROVIDE US WITH ENOUGH PD, WE MAY NOT BE ABLE TO PROVIDE YOU WITH ALL OUR PRODUCTS AND SERVICES. HOWEVER, YOU CAN ACCESS AND USE SOME PARTS OF OUR WEBSITE WITHOUT GIVING US YOUR PD.</p>
                    <strong>IS MY PERSONAL DATA SECURE?</strong>
                    <p>THE SECURITY OF YOUR PERSONAL DATA IS EXTREMELY IMPORTANT TO FSM. WE USE STRIPE TO PROCESS YOUR PURCHASES OF OUR PRODUCTS AND SERVICES. YOUR INFORMATION IS STORED IN AN SSL ENCRYPTED DATABASE.</p>
                    <p>BE SURE TO KEEP IN MIND THE NO METHOD OF TRANSMISSION OVER THE INTERNET OR METHOD OF ELECTRONIC OR PHYSICAL SERVER STORAGE IS 100% SECURE. WHILE WE VALUE THE PRIVACY AND SECURITY OF YOUR PERSONAL INFORMATION, WE CANNOT GUARANTEE AGAINST OR PREVENT POTENTIAL SECURITY BREACHES, NOR CAN WE ASSURE YOU THAT THE PERSONAL DATA THAT YOU PROVIDE US WILL NEVER BE DISCLOSED IN A MANNER INCONSISTENT WITH THIS PRIVACY POLICY.</p>
                    <p>IF YOU HAVE ANY REASON TO BELIEVE THAT THE SECURITY OF YOUR INFORMATION HAS BEEN COMPROMISED, PLEASE EMAIL US IMMEDIATELY AT HELLO@FINANCIALSUCCESSMEDIA.COM.</p>
                    <strong>GOOGLE AD AND CONTENT NETWORK PRIVACY NOTICE</strong>
                    <p>THIRD-PARTY VENDORS, INCLUDING GOOGLE, USE COOKIES TO SERVE ADS BASED ON A USER’S PAST VISITS TO OUR WEBSITE. GOOGLE’S USE OF THE DOUBLECLICK COOKIE ENABLES IT AND ITS PARTNERS TO SERVE ADS TO OUR USERS BASED ON THEIR VISITS TO OUR SITE AND/OR OTHER SITES ON THE INTERNET. USERS MAY OPT OUT OF THE USE OF THE DOUBLECLICK COOKIE FOR INTEREST-BASED ADVERTISING BY VISITING HTTP://WWW.ABOUTADS.INFO/CHOICES/</p>
                    <p>FOR EUROPEAN USERS VISIT HTTP://WWW.YOURONLINECHOICES.EU</p>
                    <strong>GOOGLE ANALYTICS PRIVACY NOTICE</strong>
                    <p>OUR WEBSITE USES GOOGLE ANALYTICS TO COLLECT INFORMATION ABOUT THE USE OF OUR WEBSITE. GOOGLE ANALYTICS COLLECTS INFORMATION FROM USERS SUCH AS AGE, GENDER, INTERESTS, DEMOGRAPHICS, HOW OFTEN THEY VISIT OUR WEBSITE, WHAT PAGES THEY VISIT, AND WHAT OTHER WEBSITES THEY HAVE USED BEFORE COMING TO OUR WEBSITE. WE USE THE INFORMATION WE GET FROM GOOGLE ANALYTICS TO ANALYZE TRAFFIC, REMARKET OUR PRODUCTS AND SERVICES TO USERS, IMPROVE OUR MARKETING, ADVERTISING, AND TO IMPROVE OUR WEBSITE. WE HAVE ENABLED GOOGLE ANALYTICS ADVERTISING FEATURES SUCH AS REMARKETING WITH GOOGLE ANALYTICS, GOOGLE DISPLAY NETWORK IMPRESSION REPORTING, AND GOOGLE ANALYTICS DEMOGRAPHICS AND INTEREST REPORTING. GOOGLE ANALYTICS COLLECTS ONLY THE IP ADDRESS ASSIGNED TO YOU ON THE DATE YOU VISIT OUR WEBSITE, NOT YOUR NAME OR OTHER IDENTIFYING INFORMATION. WE DO NOT COMBINE THE INFORMATION COLLECTED USING GOOGLE ANALYTICS WITH PD. ALTHOUGH GOOGLE ANALYTICS PLANTS A PERMANENT COOKIE ON YOUR WEB BROWSER TO IDENTIFY YOU AS A UNIQUE USER THE NEXT TIME YOU VISIT OUR WEBSITE, THE COOKIE CANNOT BE USED BY ANYONE BUT GOOGLE. GOOGLE ALSO USES SPECIFIC IDENTIFIERS TO HELP COLLECT INFORMATION ABOUT THE USE OF OUR WEBSITE. FOR MORE INFORMATION ON HOW GOOGLE COLLECTS AND PROCESSES YOUR DATA, VISIT HTTPS://WWW.GOOGLE.COM/POLICIES/PRIVACY/PARTNERS/</p>
                    <p>YOU CAN PREVENT GOOGLE ANALYTICS FROM USING YOUR INFORMATION BY OPTING OUT AT THIS LINK: HTTPS://TOOLS.GOOGLE.COM/DLPAGE/GAOPTOUT</p>
                    <strong>GOOGLE REMARKETING</strong>
                    <p>WHY AM I SEEING ADS BY GOOGLE FOR PRODUCTS I’VE VIEWED?</p>
                    <p>OUR WEBSITE USES A REMARKETING ADVERTISING SERVICE. OUR REMARKETING SERVICE IS PROVIDED BY GOOGLE AND OTHER COMPANIES THAT SHOW OUR ADS ON WEBSITES ACROSS THE INTERNET. WITH REMARKETING YOU MAY SEE ADS FOR OUR PRODUCTS YOU HAVE PREVIOUSLY LOOKED AT. AS AN EXAMPLE, SUPPOSE YOU VISIT A WEBSITE THAT SELLS COMPUTERS, BUT YOU DO NOT BUY A COMPUTER ON YOUR FIRST VISIT TO THAT WEBSITE. THE WEBSITE’S OWNER MIGHT LIKE TO ENCOURAGE YOU TO REVISIT HIS/HER SITE AND BUY A COMPUTER BY SHOWING YOU HIS/HER ADS AGAIN ON OTHER WEBSITES THAT YOU VISIT. WE USE REMARKETING FOR SIMILAR PURPOSES. FOR THIS TO HAPPEN, GOOGLE WILL READ A COOKIE THAT IS ALREADY IN YOUR BROWSER, OR THEY PLACE A COOKIE IN YOUR BROWSER WHEN YOU VISIT OUR SITE OR OTHER SITES USING REMARKETING. YOU CAN OPT OUT OF GOOGLE’S USE OF COOKIES AND REMARKETING AT THIS LINK: HTTPS://SUPPORT.GOOGLE.COM/ADS/ANSWER/2662922?HL=EN</p>
                    <p>OR YOU CAN OPT OUT USING THE NETWORK ADVERTISING INITIATIVE OPT OUT PAGE AT: HTTP://OPTOUT.NETWORKADVERTISING.ORG/#!/</p>
                    <strong>FACEBOOK REMARKETING</strong>
                    <p>THIRD PARTIES, INCLUDING FACEBOOK, MAY USE COOKIES, WEB BEACONS, AND OTHER STORAGE TECHNOLOGIES TO COLLECT OR RECEIVE INFORMATION FROM OUR WEBSITE AND ELSEWHERE ON THE INTERNET, AND USE THAT INFORMATION TO PROVIDE MEASUREMENT SERVICES AND TARGET ADS. WITH FACEBOOK REMARKETING YOU MAY SEE OUR ADS ON FACEBOOK AFTER YOU HAVE VISITED OUR SITE. FOR THIS TO HAPPEN, FACEBOOK USES A CUSTOM AUDIENCE PIXEL THAT IS ACTIVATED WHEN A VISITOR LANDS ON A WEBPAGE AND A UNIQUE “COOKIE” IS PLACED IN THEIR BROWSER. FACEBOOK LOOKALIKE AUDIENCE TARGETING ALLOWS US TO SHOW ADS ON FACEBOOK TO PEOPLE WHO ARE SIMILAR TO THOSE WHO HAVE ALREADY VISITED OR MADE A PURCHASE FROM OUR WEBSITE. TO OPT OUT OF FACEBOOK’S COLLECTION AND USE OF INFORMATION FOR AD TARGETING VISIT: HTTPS://WWW.FACEBOOK.COM/HELP/568137493302217</p>
                    <strong>DOES FINANCIAL SUCCESS MEDIA, LLC SHARE MY PERSONAL DATA WITH THIRD PARTIES?</strong>
                    <p>WE DO NOT SELL OR RENT YOUR PD TO THIRD PARTIES FOR MARKETING PURPOSES. HOWEVER, FOR DATA AGGREGATION PURPOSES WE MAY USE YOUR NPD, WHICH MIGHT BE SOLD TO OTHER PARTIES AT OUR DISCRETION. ANY SUCH DATA AGGREGATION WOULD NOT CONTAIN ANY OF YOUR PD. WE MAY PROVIDE YOUR PD TO THIRD-PARTY SERVICE PROVIDERS WE HIRE TO PROVIDE SERVICES TO US. THESE THIRD-PARTY SERVICE PROVIDERS MAY INCLUDE BUT ARE NOT LIMITED TO: PAYMENT PROCESSORS, WEB ANALYTICS COMPANIES, ADVERTISING NETWORKS, CALL CENTERS, DATA MANAGEMENT SERVICES, HELP DESK PROVIDERS, ACCOUNTANTS, LAW FIRMS, AUDITORS, SHOPPING CART AND EMAIL SERVICE PROVIDERS, AND SHIPPING COMPANIES.</p>
                    <strong>SHARING INFORMATION WITH FACEBOOK AND GOOGLE FOR MARKETING PURPOSES</strong>
                    <p>WE MAY SHARE YOUR PD WITH THIRD PARTIES FOR SIMILAR AUDIENCE MARKETING PURPOSES. SIMILAR AUDIENCE MARKETING IS ALSO CALLED LOOKALIKE AUDIENCE MARKETING. THE THIRD PARTIES WE SHARE YOUR PD WITH FOR THIS TYPE OF MARKETING INCLUDE FACEBOOK AND/OR GOOGLE. USING YOUR PD, FOR SIMILAR AUDIENCE MARKETING OR LOOKALIKE AUDIENCE MARKETING HELPS US FIND NEW AUDIENCES (USERS AND CUSTOMERS) BASED ON SIMILAR INTERESTS TO YOURS, THIS HELPS US IMPROVE OUR MARKETING SERVICES. YOUR PD IS ONLY SHARED WITH FACEBOOK AND GOOGLE FOR THIS TYPE OF MARKETING. BY USING OUR WEBSITE AND AGREEING TO OUR PRIVACY NOTICE, YOU ARE GIVING YOUR CONSENT FOR YOUR PD TO BE USED FOR THE MARKETING PURPOSES DESCRIBED WITHIN THIS SECTION.</p>

                    <strong>LEGALLY REQUIRED RELEASES OF INFORMATION</strong>
                    <p>WE MAY BE LEGALLY REQUIRED TO DISCLOSE YOUR PD IF SUCH DISCLOSURE IS (A) REQUIRED BY SUBPOENA, LAW, OR OTHER LEGAL PROCESS; (B) NECESSARY TO ASSIST LAW ENFORCEMENT OFFICIALS OR GOVERNMENT ENFORCEMENT AGENCIES; (C) NECESSARY TO INVESTIGATE VIOLATIONS OF OR OTHERWISE ENFORCE OUR LEGAL TERMS; (D) NECESSARY TO PROTECT US FROM LEGAL ACTION OR CLAIMS FROM THIRD PARTIES, INCLUDING YOU AND/OR OTHER USERS OR MEMBERS; OR (E) NECESSARY TO PROTECT THE LEGAL RIGHTS, PERSONAL/REAL PROPERTY, OR PERSONAL SAFETY OF OUR COMPANY, USERS, EMPLOYEES, AND AFFILIATES.</p>

                    <strong>COMMUNITY DISCUSSION BOARDS</strong>
                    <p>OUR WEBSITE MAY OFFER THE ABILITY FOR USERS TO COMMUNICATE WITH EACH OTHER THROUGH ONLINE COMMUNITY DISCUSSION BOARDS OR OTHER MECHANISMS. WE DO NOT FILTER OR MONITOR WHAT IS POSTED ON SUCH DISCUSSION BOARDS. IF YOU CHOOSE TO POST ON THESE DISCUSSION BOARDS, YOU SHOULD USE CARE WHEN EXPOSING ANY PD, AS SUCH INFORMATION IS NOT PROTECTED BY OUR PRIVACY NOTICE NOR ARE WE LIABLE IF YOU CHOOSE TO DISCLOSE YOUR PD THROUGH SUCH POSTINGS. ALSO, PD YOU POST ON OUR WEBSITE FOR PUBLICATION MAY BE AVAILABLE WORLDWIDE BY MEANS OF THE INTERNET. WE CANNOT PREVENT THE USE OR MISUSE OF SUCH INFORMATION BY OTHERS.</p>

                    <strong>RETAINING AND DESTROYING YOUR PD</strong>
                    <p>WE RETAIN INFORMATION THAT WE COLLECT FROM YOU (INCLUDING YOUR PD) ONLY FOR AS LONG AS WE NEED IT FOR LEGAL, BUSINESS, OR TAX PURPOSES. YOUR INFORMATION MAY BE RETAINED IN ELECTRONIC FORM, PAPER FORM, OR A COMBINATION OF BOTH. WHEN YOUR INFORMATION IS NO LONGER NEEDED, WE WILL DESTROY, DELETE, OR ERASE IT.</p>

                    <strong>UPDATING YOUR PD</strong>
                    <p>YOU CAN UPDATE YOUR PD USING SERVICES FOUND ON OUR WEBSITE. IF NO SUCH SERVICES EXIST, YOU CAN CONTACT US USING THE CONTACT INFORMATION FOUND AT THE TOP OF THIS PRIVACY NOTICE AND WE WILL HELP YOU. HOWEVER, WE MAY KEEP YOUR PD AS NEEDED TO ENFORCE OUR AGREEMENTS AND TO COMPLY WITH ANY LEGAL OBLIGATIONS.</p>

                    <strong>REVOKING YOUR CONSENT FOR USING YOUR PD</strong>
                    <p>YOU HAVE THE RIGHT TO REVOKE YOUR CONSENT FOR US TO USE YOUR PD AT ANY TIME. SUCH AN OPTOUT WILL NOT AFFECT DISCLOSURES OTHERWISE PERMITTED BY LAW INCLUDING BUT NOT LIMITED TO: (I) DISCLOSURES TO AFFILIATES AND BUSINESS PARTNERS, (II) DISCLOSURES TO THIRD-PARTY SERVICE PROVIDERS THAT PROVIDE CERTAIN SERVICES FOR OUR BUSINESS, SUCH AS CREDIT CARD PROCESSING, COMPUTER SYSTEM SERVICES, SHIPPING, DATA MANAGEMENT SERVICES, (III) DISCLOSURES TO THIRD PARTIES AS NECESSARY TO FULFILL YOUR REQUESTS, (IV) DISCLOSURES TO GOVERNMENTAL AGENCIES OR LAW ENFORCEMENT DEPARTMENTS, OR AS OTHERWISE REQUIRED TO BE MADE UNDER APPLICABLE LAW, (V) PREVIOUSLY COMPLETED DISCLOSURES TO THIRD PARTIES, OR (VI) DISCLOSURES TO THIRD PARTIES IN CONNECTION WITH SUBSEQUENT CONTESTS OR PROMOTIONS YOU MAY CHOOSE TO ENTER, OR THIRD-PARTY OFFERS YOU MAY CHOOSE TO ACCEPT. IF YOU WANT TO REVOKE YOUR CONSENT FOR US TO USE YOUR PD, SEND US AN EMAIL WITH YOUR REQUEST TO: HELLO@FINANCIALSUCCESSMEDIA.COM</p>
                    <strong>PROTECTING THE PRIVACY RIGHTS OF THIRD PARTIES</strong>
                    <p>IF ANY POSTINGS YOU MAKE ON OUR WEBSITE CONTAIN INFORMATION ABOUT THIRD PARTIES, YOU MUST MAKE SURE YOU HAVE PERMISSION TO INCLUDE THAT INFORMATION IN YOUR POSTING. WHILE WE ARE NOT LEGALLY LIABLE FOR THE ACTIONS OF OUR USERS, WE WILL REMOVE ANY POSTINGS ABOUT WHICH WE ARE NOTIFIED, IF SUCH POSTINGS VIOLATE THE PRIVACY RIGHTS OF OTHERS.</p>

                    <strong>DO NOT TRACK SETTINGS</strong>
                    <p>SOME WEB BROWSERS HAVE SETTINGS THAT ENABLE YOU TO REQUEST THAT OUR WEBSITE NOT TRACK YOUR MOVEMENT WITHIN OUR WEBSITE. OUR WEBSITE DOES NOT OBEY SUCH SETTINGS WHEN TRANSMITTED TO AND DETECTED BY OUR WEBSITE. YOU CAN TURN OFF TRACKING FEATURES AND OTHER SECURITY SETTINGS IN YOUR BROWSER BY REFERRING TO YOUR BROWSER’S USER MANUAL.</p>
                    <strong>LINKS TO OTHER WEBSITES</strong>
                    <p>OUR WEBSITE MAY CONTAIN LINKS TO OTHER WEBSITES. THESE WEBSITES ARE NOT UNDER OUR CONTROL AND ARE NOT SUBJECT TO OUR PRIVACY NOTICE. THESE WEBSITES WILL LIKELY HAVE THEIR OWN PRIVACY NOTICES. WE HAVE NO RESPONSIBILITY FOR THESE WEBSITES AND WE PROVIDE LINKS TO THESE WEBSITES SOLELY FOR YOUR CONVENIENCE. YOU ACKNOWLEDGE THAT YOUR USE OF AND ACCESS TO THESE WEBSITES ARE SOLELY AT YOUR RISK. IT IS YOUR RESPONSIBILITY TO CHECK THE PRIVACY NOTICES OF THESE WEBSITES TO SEE HOW THEY TREAT YOUR PD.</p>
                    <strong>DOES THIS WEBSITE COMPLY WITH THE CHILDREN’S ONLINE PRIVACY PROTECTION ACT?</strong>

                    <p>EVEN THOUGH OUR WEBSITE IS NOT DESIGNED FOR USE BY ANYONE UNDER THE AGE OF 13, WE REALIZE THAT A CHILD UNDER THE AGE OF 13 MAY ATTEMPT TO ACCESS OUR WEBSITE. WE DO NOT KNOWINGLY COLLECT PD FROM CHILDREN UNDER THE AGE OF 13. IF YOU ARE A PARENT OR GUARDIAN AND BELIEVE THAT YOUR CHILD IS USING OUR WEBSITE, PLEASE CONTACT US. BEFORE WE REMOVE ANY INFORMATION, WE MAY ASK FOR PROOF OF IDENTIFICATION TO PREVENT MALICIOUS REMOVAL OF ACCOUNT INFORMATION. IF WE DISCOVER THAT A CHILD IS ACCESSING OUR WEBSITE, WE WILL DELETE HIS/HER INFORMATION WITHIN A REASONABLE PERIOD OF TIME. YOU ACKNOWLEDGE THAT WE DO NOT VERIFY THE AGE OF OUR USERS NOR DO WE HAVE ANY LIABILITY TO DO SO.
                    </p>
                    <strong>OPT-OUT</strong>

                    <p>IF IT ANYTIME YOU DO NOT WISH TO RECEIVE OFFERS OR EMAILS FROM US, YOU MUST TELL US IN ORDER FOR THE COMMUNICATIONS TO STOP. YOU MAY DO THIS BY EITHER CLICKING THE UNSUBSCRIBE LINK LOCATED AT THE BOTTOM OF OUR EMAILS OR YOU MAY CONTACT US DIRECTLY BY SENDING AN EMAIL TO HELLO@FINANCIALSUCCESSMEDIA.COM.
                    </p>
                  </section>
                  <section>
                    <h3 className="text-lg font-medium mt-4 mb-2">OUR SECURITY POLICY</h3>

                    <p>WE HAVE BUILT OUR WEBSITE USING INDUSTRY-STANDARD SECURITY MEASURES AND AUTHENTICATION TOOLS TO PROTECT THE SECURITY OF YOUR PD. WE AND THE THIRD PARTIES WHO PROVIDE SERVICES FOR US, ALSO MAINTAIN TECHNICAL AND PHYSICAL SAFEGUARDS TO PROTECT YOUR PD. WHEN WE COLLECT YOUR CREDIT CARD INFORMATION THROUGH OUR WEBSITE, WE WILL ENCRYPT IT BEFORE IT TRAVELS OVER THE INTERNET USING INDUSTRY-STANDARD TECHNOLOGY FOR CONDUCTING SECURE ONLINE TRANSACTIONS. UNFORTUNATELY, WE CANNOT GUARANTEE AGAINST THE LOSS OR MISUSE OF YOUR PD OR SECURE DATA TRANSMISSION OVER THE INTERNET BECAUSE OF ITS NATURE.
                    </p>
                    <p>WE STRONGLY URGE YOU TO PROTECT ANY PASSWORD YOU MAY HAVE FOR OUR WEBSITE AND TO NOT SHARE IT WITH ANYONE. YOU SHOULD ALWAYS LOG OUT OF OUR WEBSITE WHEN YOU FINISH USING IT, ESPECIALLY IF YOU ARE SHARING OR USING A COMPUTER IN A PUBLIC PLACE.</p>

                    <strong>USE OF YOUR CREDIT CARD</strong>
                    <p>
                    YOU MAY HAVE TO PROVIDE A CREDIT CARD TO BUY PRODUCTS AND SERVICES FROM OUR WEBSITE. WE USE THIRD-PARTY BILLING SERVICES AND HAVE NO CONTROL OVER THESE SERVICES. WE USE OUR COMMERCIALLY REASONABLE EFFORTS TO MAKE SURE YOUR CREDIT CARD NUMBER IS KEPT STRICTLY CONFIDENTIAL BY USING ONLY THIRD-PARTY BILLING SERVICES THAT USE INDUSTRY-STANDARD ENCRYPTION TECHNOLOGY TO PROTECT YOUR CREDIT CARD NUMBER FROM UNAUTHORIZED USE. HOWEVER, YOU UNDERSTAND AND AGREE THAT WE ARE IN NO WAY RESPONSIBLE FOR ANY MISUSE OF YOUR CREDIT CARD NUMBER.
                    </p>
                    <strong>TRANSFERRING PD FROM THE EUROPEAN UNION</strong>
                    <p>(PD) THAT WE COLLECT FROM YOU MAY BE STORED, PROCESSED, AND TRANSFERRED BETWEEN ANY OF THE COUNTRIES IN WHICH WE OPERATE, SPECIFICALLY THE UNITED STATES. THE EUROPEAN UNION HAS NOT FOUND THE UNITED STATES AND SOME OTHER COUNTRIES TO HAVE AN ADEQUATE LEVEL OF PROTECTION OF PD UNDER ARTICLE 45 OF THE GDPR. OUR COMPANY RELIES ON DEROGATIONS FOR SPECIFIC SITUATIONS AS DEFINED IN ARTICLE 49 OF THE GDPR. FOR EUROPEAN UNION CUSTOMERS AND USERS, WITH YOUR CONSENT, YOUR PD MAY BE TRANSFERRED OUTSIDE THE EUROPEAN UNION TO THE UNITED STATES. WE WILL USE YOUR PD TO PROVIDE THE GOODS, SERVICES, AND/OR INFORMATION YOU REQUEST FROM US TO PERFORM A CONTRACT WITH YOU OR TO SATISFY A LEGITIMATE INTEREST OF OUR COMPANY IN A MANNER THAT DOES NOT OUTWEIGH YOUR FREEDOMS AND RIGHTS. WHEREVER WE TRANSFER, PROCESS OR STORE YOUR PD, WE WILL TAKE REASONABLE STEPS TO PROTECT IT. WE WILL USE THE INFORMATION WE COLLECT FROM YOU IN ACCORDANCE WITH OUR PRIVACY NOTICE. BY USING OUR WEBSITE, SERVICES, OR PRODUCTS, YOU AGREE TO THE TRANSFERS OF YOUR PD DESCRIBED WITHIN THIS SECTION.</p>
                  </section>
                  <section>
                    <h3 className="text-lg font-medium mt-4 mb-2">HOW WILL I BE NOTIFIED OF CHANGES TO THIS PRIVACY POLICY?</h3>

                    <p>WE MAY CHANGE OUR PRIVACY POLICY FROM TIME TO TIME. IF WE MAKE A MATERIAL CHANGE TO THIS POLICY, WE WILL POST THE CHANGES ON THIS PRIVACY POLICY WEBPAGE AND MAY ALSO INFORM YOU OF THE CHANGES BY OTHER MEANS SUCH AS OUR NEWSLETTER OR IN A DIRECT EMAIL CONTAINING THE UPDATE.
                    </p>
                    <p>WHAT IF I HAVE QUESTIONS ABOUT THIS PRIVACY POLICY OR TERMS OF USE?
                    </p>
                    <p>IF YOU HAVE ANY QUESTIONS ABOUT THIS PRIVACY POLICY OR TERMS OF USE, PLEASE DIRECT YOUR QUESTIONS TO FINANCIAL SUCCESS MEDIA, LLC USING THE INFORMATION BELOW:
                    </p>
                    <div className="mt-2 space-y-1">
                      <p>Financial Success Media, LLC</p>
                      <a
                      href="mailto:hello@financialsuccessmedia.com"
                      className="text-[#81b29a] hover:text-[#81b29a]/80"
                      >
                        hello@financialsuccessmedia.com
                    </a>
                    <p>404-410-0779</p>
                    </div>
                  </section>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};