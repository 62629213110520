import React from 'react';
import { PRICING_TIERS } from '../lib/pricing';
import { PricingTier } from './PricingTier';
import { Footer } from './Footer';
import { Navbar } from './Navbar';

export const PricingPage: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      
      <div className="flex-grow bg-gray-50 pt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              Simple, Transparent Pricing
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Purchase transcription hours at your own pace. No subscriptions, no commitments.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8 max-w-7xl mx-auto">
            {Object.entries(PRICING_TIERS).map(([key, tier]) => (
              <PricingTier
                key={key}
                tier={tier}
              />
            ))}
          </div>

          <div className="mt-16 text-center">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              Why Buy More Hours?
            </h2>
            <div className="grid md:grid-cols-3 gap-8 max-w-3xl mx-auto mt-8">
              <div className="bg-white p-6 rounded-lg shadow-sm">
                <div className="text-2xl font-bold text-[#81b29a] mb-2">$6/hour</div>
                <p className="text-gray-600">Starter Package</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-sm border-2 border-[#81b29a]">
                <div className="text-2xl font-bold text-[#81b29a] mb-2">$4/hour</div>
                <p className="text-gray-600">Pro Package</p>
                <p className="text-sm text-[#81b29a] mt-1">Save 33%</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-sm">
                <div className="text-2xl font-bold text-[#81b29a] mb-2">$3.33/hour</div>
                <p className="text-gray-600">Enterprise Package</p>
                <p className="text-sm text-[#81b29a] mt-1">Save 44%</p>
              </div>
            </div>
          </div>

          <div className="mt-16 bg-white rounded-xl shadow-sm p-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold text-gray-900 mb-4 text-center">
              Frequently Asked Questions
            </h2>
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  Do the hours expire?
                </h3>
                <p className="text-gray-600">
                  No, your purchased hours never expire. Use them at your own pace.
                </p>
              </div>
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  Can I buy more hours later?
                </h3>
                <p className="text-gray-600">
                  Yes, you can purchase additional hours at any time. They'll be added to your existing balance.
                </p>
              </div>
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  How accurate are the transcriptions?
                </h3>
                <p className="text-gray-600">
                  Our AI-powered transcription service achieves 99%+ accuracy for clear audio in supported languages.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};