import React from 'react';
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import { CheckCircle2, Mail, Clock } from 'lucide-react';

interface PurchaseConfirmationProps {
  email: string;
  tierName: string;
  minutes: number;
}

export const PurchaseConfirmation: React.FC<PurchaseConfirmationProps> = ({ 
  email,
  tierName,
  minutes 
}) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <div className="flex-grow bg-gradient-to-br from-[#81b29a]/10 to-blue-50 flex items-center justify-center px-4 pt-16">
        <div className="max-w-md w-full bg-white rounded-xl shadow-lg p-8">
          <div className="text-center">
            <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100 mb-6">
              <CheckCircle2 className="h-10 w-10 text-green-500" />
            </div>
            
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              Purchase Successful!
            </h2>
            
            <div className="space-y-6 mb-8">
              <div>
                <p className="text-gray-600 mb-2">
                  You've purchased the {tierName} package:
                </p>
                <div className="bg-[#81b29a]/10 rounded-lg p-4">
                  <div className="flex items-center justify-center gap-2">
                    <Clock className="h-5 w-5 text-[#81b29a]" />
                    <span className="font-medium text-gray-900">{minutes} minutes of transcription</span>
                  </div>
                </div>
              </div>

              <div>
                <p className="text-gray-600 mb-2">
                  We've sent a sign-in link to:
                </p>
                <div className="flex items-center justify-center gap-2 font-medium text-gray-900 bg-gray-50 py-2 px-4 rounded-lg">
                  <Mail className="h-5 w-5 text-[#81b29a]" />
                  {email}
                </div>
              </div>

              <div className="text-sm text-gray-600">
                <p>Click the link in your email to:</p>
                <ul className="mt-2 space-y-1">
                  <li>• Access your dashboard</li>
                  <li>• Start transcribing your media</li>
                  <li>• View your available minutes</li>
                </ul>
              </div>
            </div>

            <div className="text-sm text-gray-500">
              <p>Can't find the email? Check your spam folder or</p>
              <button
                onClick={() => window.location.reload()}
                className="text-[#81b29a] hover:text-[#81b29a]/80 font-medium"
              >
                click here to try again
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};