import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { signInWithOTP, verifySession } from '../lib/auth';
import { Glasses, Loader2 } from 'lucide-react';
import { SignupConfirmation } from './SignupConfirmation';
import { PurchaseConfirmation } from './PurchaseConfirmation';
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import toast from 'react-hot-toast';

const TIER_MINUTES = {
  'Starter': 5 * 60,
  'Pro': 15 * 60,
  'Enterprise': 30 * 60
};

export const SignupPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [purchaseDetails, setPurchaseDetails] = useState<{
    email: string;
    tierName: string;
    minutes: number;
  } | null>(null);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });

  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check if user is already logged in
        const { data: { session } } = await supabase.auth.getSession();
        if (session) {
          window.location.href = '/dashboard';
          return;
        }

        // Check for checkout session
        const params = new URLSearchParams(window.location.search);
        const sessionId = params.get('session_id');
        
        if (sessionId) {
          await handleCheckoutSession(sessionId);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error('Session check error:', error);
        setError(error instanceof Error ? error.message : 'Failed to verify session');
        setLoading(false);
      }
    };

    checkSession();
  }, []);

  const handleCheckoutSession = async (sessionId: string) => {
    try {
      const data = await verifySession(sessionId);
      
      if (!data.customer_email || !data.tier_name) {
        throw new Error('Invalid checkout session data');
      }

      // Send magic link for immediate access
      await signInWithOTP(data.customer_email, `${window.location.origin}/dashboard`);

      // Show purchase confirmation
      setPurchaseDetails({
        email: data.customer_email,
        tierName: data.tier_name,
        minutes: TIER_MINUTES[data.tier_name as keyof typeof TIER_MINUTES] || 0
      });

      // Update form data
      setFormData(prev => ({ ...prev, email: data.customer_email }));
      setLoading(false);
    } catch (error) {
      console.error('Checkout verification error:', error);
      setError(error instanceof Error ? error.message : 'Failed to verify checkout session');
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (formData.password !== formData.confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    setLoading(true);

    try {
      const { error } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          emailRedirectTo: `${window.location.origin}/dashboard`
        }
      });

      if (error) throw error;

      setShowConfirmation(true);
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'Failed to create account');
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col">
        <Navbar />
        <div className="flex-grow flex items-center justify-center bg-gradient-to-br from-[#81b29a]/10 to-blue-50 pt-16">
          <div className="flex flex-col items-center">
            <Loader2 className="h-8 w-8 animate-spin text-[#81b29a]" />
            <p className="mt-2 text-gray-600">Setting up your account...</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex flex-col">
        <Navbar />
        <div className="flex-grow flex items-center justify-center bg-gradient-to-br from-[#81b29a]/10 to-blue-50 pt-16">
          <div className="text-center">
            <div className="bg-red-50 p-6 rounded-lg shadow-sm">
              <p className="text-red-700 mb-4">{error}</p>
              <a
                href="/pricing"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-[#81b29a] hover:bg-[#81b29a]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#81b29a]"
              >
                Return to pricing
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  if (purchaseDetails) {
    return (
      <PurchaseConfirmation
        email={purchaseDetails.email}
        tierName={purchaseDetails.tierName}
        minutes={purchaseDetails.minutes}
      />
    );
  }

  if (showConfirmation) {
    return (
      <SignupConfirmation 
        email={formData.email}
        onContinue={() => window.location.href = '/dashboard'}
      />
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <div className="flex-grow bg-gradient-to-br from-[#81b29a]/10 to-blue-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 pt-24">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex justify-center">
            <Glasses className="h-12 w-12 text-[#81b29a] transform -rotate-12" />
          </div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Create your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Start transcribing your media with Voxi Scribe
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    type="email"
                    value={formData.email}
                    onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#81b29a] focus:border-[#81b29a] sm:text-sm"
                    placeholder="you@example.com"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    type="password"
                    required
                    value={formData.password}
                    onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#81b29a] focus:border-[#81b29a] sm:text-sm"
                    placeholder="••••••••"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                  Confirm password
                </label>
                <div className="mt-1">
                  <input
                    id="confirmPassword"
                    type="password"
                    required
                    value={formData.confirmPassword}
                    onChange={(e) => setFormData(prev => ({ ...prev, confirmPassword: e.target.value }))}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#81b29a] focus:border-[#81b29a] sm:text-sm"
                    placeholder="••••••••"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#81b29a] hover:bg-[#81b29a]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#81b29a] disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? (
                    <span className="flex items-center">
                      <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5" />
                      Creating account...
                    </span>
                  ) : (
                    'Create account'
                  )}
                </button>
              </div>

              <div className="text-sm text-center">
                <p className="text-gray-600">
                  Already have an account?{' '}
                  <a href="/login" className="font-medium text-[#81b29a] hover:text-[#81b29a]/80">
                    Sign in
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};