export function formatTimestamp(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);
  const ms = Math.floor((seconds % 1) * 1000);

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')},${String(ms).padStart(3, '0')}`;
}

export function convertToSRT(text: string): string {
  const WORDS_PER_SUBTITLE = 10;
  const SECONDS_PER_WORD = 0.4;
  
  const words = text.split(/\s+/);
  const subtitles: string[] = [];
  let index = 1;
  let currentTime = 0;

  for (let i = 0; i < words.length; i += WORDS_PER_SUBTITLE) {
    const chunk = words.slice(i, i + WORDS_PER_SUBTITLE);
    const duration = chunk.length * SECONDS_PER_WORD;
    
    const startTime = formatTimestamp(currentTime);
    currentTime += duration;
    const endTime = formatTimestamp(currentTime);

    subtitles.push(
      `${index}\n${startTime} --> ${endTime}\n${chunk.join(' ')}\n`
    );

    index++;
  }

  return subtitles.join('\n');
}