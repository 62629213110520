import React from 'react';
import { Clock, Activity, Plus } from 'lucide-react';

interface MinutesOverviewProps {
  availableMinutes: number;
  usedMinutes: number;
}

export const MinutesOverview: React.FC<MinutesOverviewProps> = ({
  availableMinutes,
  usedMinutes
}) => {
  const percentage = Math.min(
    (usedMinutes / (availableMinutes + usedMinutes)) * 100 || 0,
    100
  );

  const handlePurchaseClick = () => {
    window.location.href = '/pricing';
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-3">
          <Clock className="h-5 w-5 text-[#81b29a]" />
          <h2 className="text-xl font-semibold text-gray-900">Minutes Overview</h2>
        </div>
        <button
          onClick={handlePurchaseClick}
          className="inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-md text-white bg-[#81b29a] hover:bg-[#81b29a]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#81b29a] transition-colors"
        >
          <Plus className="h-4 w-4 mr-1" />
          Purchase Minutes
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="p-4 bg-[#81b29a]/10 rounded-lg">
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-600">Available Minutes</span>
            <Clock className="h-4 w-4 text-[#81b29a]" />
          </div>
          <p className="mt-2 text-2xl font-bold text-gray-900">
            {availableMinutes.toFixed(1)}
          </p>
        </div>

        <div className="p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-600">Used Minutes</span>
            <Activity className="h-4 w-4 text-gray-600" />
          </div>
          <p className="mt-2 text-2xl font-bold text-gray-900">
            {usedMinutes.toFixed(1)}
          </p>
        </div>
      </div>

      <div className="mt-6">
        <div className="flex items-center justify-between text-sm text-gray-600 mb-2">
          <span>Usage</span>
          <span>{percentage.toFixed(1)}%</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div
            className="bg-[#81b29a] h-2 rounded-full transition-all duration-300"
            style={{ width: `${percentage}%` }}
          />
        </div>
      </div>
    </div>
  );
};