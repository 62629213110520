import { AuthError } from '@supabase/supabase-js';
import { supabase } from './supabase';

// Throttle configuration
const THROTTLE_DURATION = 60000; // 1 minute
const MAX_RETRIES = 3;
const RETRY_DELAY = 2000; // 2 seconds

interface ThrottleState {
  lastAttempt: number;
  attempts: number;
}

const throttleMap = new Map<string, ThrottleState>();

export async function signInWithOTP(email: string, redirectTo?: string) {
  const throttleKey = `otp_${email}`;
  const now = Date.now();
  const throttleState = throttleMap.get(throttleKey) || { lastAttempt: 0, attempts: 0 };

  // Check if throttled
  if (now - throttleState.lastAttempt < THROTTLE_DURATION) {
    const waitTime = Math.ceil((THROTTLE_DURATION - (now - throttleState.lastAttempt)) / 1000);
    throw new Error(`Please wait ${waitTime} seconds before requesting another sign-in link`);
  }

  // Reset attempts if enough time has passed
  if (now - throttleState.lastAttempt > THROTTLE_DURATION) {
    throttleState.attempts = 0;
  }

  // Update throttle state
  throttleState.lastAttempt = now;
  throttleState.attempts++;
  throttleMap.set(throttleKey, throttleState);

  try {
    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: redirectTo
      }
    });

    if (error) throw error;
    return { error: null };
  } catch (error) {
    console.error('OTP error:', error);
    throw error;
  }
}

export async function verifySession(sessionId: string) {
  if (!sessionId) {
    throw new Error('Session ID is required');
  }

  const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
  const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

  if (!supabaseUrl || !supabaseKey) {
    throw new Error('Missing Supabase configuration');
  }

  try {
    const response = await fetch(
      `${supabaseUrl}/functions/v1/verify-checkout-session`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'apikey': supabaseKey,
          'Authorization': `Bearer ${supabaseKey}`
        },
        body: JSON.stringify({ sessionId })
      }
    );

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.error || `Verification failed: ${response.status}`);
    }

    const data = await response.json();
    
    if (!data.customer_email || !data.tier_name) {
      throw new Error('Invalid response from verification endpoint');
    }

    return data;
  } catch (error) {
    console.error('Session verification error:', error);
    throw error;
  }
}