import React from 'react';
import { CheckCircle2, Mail } from 'lucide-react';
import { Navbar } from './Navbar';
import { Footer } from './Footer';

interface SignupConfirmationProps {
  email: string;
  onContinue: () => void;
}

export const SignupConfirmation: React.FC<SignupConfirmationProps> = ({ email }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <div className="flex-grow bg-gradient-to-br from-[#81b29a]/10 to-blue-50 flex items-center justify-center px-4 pt-16">
        <div className="max-w-md w-full bg-white rounded-xl shadow-lg p-8">
          <div className="text-center">
            <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100 mb-6">
              <CheckCircle2 className="h-10 w-10 text-green-500" />
            </div>
            
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              Check Your Email
            </h2>
            
            <div className="space-y-4 mb-8">
              <p className="text-gray-600">
                We've sent a sign-in link to:
              </p>
              <div className="flex items-center justify-center gap-2 font-medium text-gray-900 bg-gray-50 py-2 px-4 rounded-lg">
                <Mail className="h-5 w-5 text-[#81b29a]" />
                {email}
              </div>
              <p className="text-gray-600">
                Click the link in your email to sign in to your account and access your dashboard.
              </p>
            </div>

            <div className="text-sm text-gray-500">
              <p>Can't find the email? Check your spam folder or</p>
              <button
                onClick={() => window.location.reload()}
                className="text-[#81b29a] hover:text-[#81b29a]/80 font-medium"
              >
                click here to try again
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};