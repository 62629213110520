import type { CheckoutParams, CheckoutResponse } from './types';

export async function createCheckoutSession(params: CheckoutParams): Promise<CheckoutResponse> {
  try {
    const { priceId, tierName } = params;

    if (!priceId) {
      throw new Error('Price ID is required');
    }

    const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
    const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

    if (!supabaseUrl || !supabaseKey) {
      throw new Error('Missing required configuration');
    }

    const response = await fetch(`${supabaseUrl}/functions/v1/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'apikey': supabaseKey,
        'Authorization': `Bearer ${supabaseKey}`
      },
      body: JSON.stringify({
        priceId,
        tierName,
        mode: 'payment' // Change to one-time payment mode
      })
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.error || `Request failed with status: ${response.status}`);
    }

    const contentType = response.headers.get('content-type');
    if (!contentType || !contentType.includes('application/json')) {
      throw new Error('Invalid response format from server');
    }

    const data = await response.json();

    if (!data?.url) {
      throw new Error('No checkout URL returned from server');
    }

    return { url: data.url, error: null };
  } catch (error) {
    const errorMessage = error instanceof Error 
      ? error.message 
      : 'An unexpected error occurred during checkout';
      
    console.error('Checkout error:', errorMessage);
    
    return {
      url: null,
      error: errorMessage
    };
  }
}