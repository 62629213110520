import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
const assemblyAiKey = import.meta.env.VITE_ASSEMBLYAI_API_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
  }
});

export interface TranscriptionJob {
  id: string;
  video_url: string;
  status: 'pending' | 'processing' | 'completed' | 'failed';
  transcription_text?: string;
  error_message?: string;
  external_id?: string;
  user_id: string;
  filename?: string;
  minutes?: number;
}

export class TranscriptionError extends Error {
  constructor(message: string, public readonly details?: any) {
    super(message);
    this.name = 'TranscriptionError';
  }
}

function calculateMinutes(text: string): number {
  const words = text.split(/\s+/).filter(word => word.length > 0).length;
  return Math.max(0.5, Math.ceil(words / 150));
}

export async function uploadVideo(file: File): Promise<{ url: string; filename: string }> {
  try {
    const fileExt = file.name.split('.').pop();
    const uniqueId = Math.random().toString(36).substring(2);
    const fileName = `${uniqueId}.${fileExt}`;

    const { data, error } = await supabase.storage
      .from('videos')
      .upload(fileName, file, {
        cacheControl: '3600',
        upsert: false
      });

    if (error) {
      throw new TranscriptionError('Failed to upload file', error);
    }

    if (!data?.path) {
      throw new TranscriptionError('Upload successful but file path is missing');
    }

    const { data: { publicUrl } } = supabase.storage
      .from('videos')
      .getPublicUrl(data.path);

    if (!publicUrl) {
      throw new TranscriptionError('Failed to generate public URL for uploaded file');
    }

    return { url: publicUrl, filename: file.name };
  } catch (error) {
    if (error instanceof TranscriptionError) {
      throw error;
    }
    throw new TranscriptionError('Failed to upload file', error);
  }
}

function getFilenameFromUrl(url: string): string | undefined {
  try {
    const urlObj = new URL(url);
    const pathSegments = urlObj.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];
    const decodedFilename = decodeURIComponent(lastSegment).split(/[?#]/)[0];
    return decodedFilename || undefined;
  } catch {
    return undefined;
  }
}

export async function createTranscriptionJob(videoUrl: string, filename?: string): Promise<TranscriptionJob> {
  if (!assemblyAiKey) {
    throw new TranscriptionError('AssemblyAI API key not configured');
  }

  try {
    const { data: { user } } = await supabase.auth.getUser();
    
    if (!user) {
      throw new TranscriptionError('User must be authenticated to create transcription jobs');
    }

    // First create the transcription record
    const { data: transcription, error: insertError } = await supabase
      .from('transcriptions')
      .insert([
        {
          video_url: videoUrl,
          status: 'pending',
          user_id: user.id,
          filename: filename || getFilenameFromUrl(videoUrl)
        }
      ])
      .select()
      .single();

    if (insertError || !transcription) {
      throw new TranscriptionError('Failed to create transcription record', insertError);
    }

    // Then start the AssemblyAI transcription
    try {
      const response = await fetch('https://api.assemblyai.com/v2/transcript', {
        method: 'POST',
        headers: {
          'Authorization': assemblyAiKey,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          audio_url: videoUrl,
          language_detection: true,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new TranscriptionError(
          `AssemblyAI Error: ${errorData.error || 'Unknown error'}`,
          errorData
        );
      }

      const result = await response.json();
      
      if (!result.id) {
        throw new TranscriptionError('No transcription ID returned from AssemblyAI');
      }

      // Update the transcription record with the external ID
      const { data: updatedTranscription, error: updateError } = await supabase
        .from('transcriptions')
        .update({
          external_id: result.id,
          status: 'processing'
        })
        .eq('id', transcription.id)
        .select()
        .single();

      if (updateError || !updatedTranscription) {
        throw new TranscriptionError('Failed to update transcription with external ID', updateError);
      }

      return updatedTranscription;
    } catch (error) {
      // If AssemblyAI fails, update the status to failed
      await supabase
        .from('transcriptions')
        .update({
          status: 'failed',
          error_message: error instanceof Error ? error.message : 'Failed to start transcription'
        })
        .eq('id', transcription.id);

      throw error;
    }
  } catch (error) {
    if (error instanceof TranscriptionError) {
      throw error;
    }
    throw new TranscriptionError('Failed to start transcription process', error);
  }
}

export async function getTranscriptionStatus(jobId: string): Promise<TranscriptionJob> {
  if (!assemblyAiKey) {
    throw new TranscriptionError('AssemblyAI API key not configured');
  }

  try {
    // Get the current transcription record
    const { data: transcription, error: getError } = await supabase
      .from('transcriptions')
      .select('*')
      .eq('id', jobId)
      .single();

    if (getError || !transcription) {
      throw new TranscriptionError('Failed to fetch transcription', getError);
    }

    if (!transcription.external_id) {
      throw new TranscriptionError('No external ID found for transcription');
    }

    // Check status with AssemblyAI
    const response = await fetch(
      `https://api.assemblyai.com/v2/transcript/${transcription.external_id}`,
      {
        headers: {
          'Authorization': assemblyAiKey,
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new TranscriptionError(
        `AssemblyAI Error: ${errorData.error || 'Unknown error'}`,
        errorData
      );
    }

    const result = await response.json();

    // Only update if the status has changed
    if (
      (result.status === 'completed' && transcription.status !== 'completed') ||
      (result.status === 'error' && transcription.status !== 'failed') ||
      (result.status === 'processing' && transcription.status === 'pending')
    ) {
      const updateData: Record<string, any> = {
        status: result.status === 'completed' ? 'completed' : 
                result.status === 'error' ? 'failed' : 'processing'
      };

      if (result.status === 'completed' && result.text) {
        updateData.transcription_text = result.text;
        updateData.minutes = calculateMinutes(result.text);
      } else if (result.status === 'error') {
        updateData.error_message = result.error;
      }

      // Update the database with the new status
      const { data: updatedTranscription, error: updateError } = await supabase
        .from('transcriptions')
        .update(updateData)
        .eq('id', jobId)
        .select()
        .single();

      if (updateError || !updatedTranscription) {
        throw new TranscriptionError('Failed to update transcription status', updateError);
      }

      return updatedTranscription;
    }

    return transcription;
  } catch (error) {
    if (error instanceof TranscriptionError) {
      throw error;
    }
    throw new TranscriptionError('Failed to check transcription status', error);
  }
}