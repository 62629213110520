import React, { useState, useEffect } from 'react';
import { Glasses, LogOut } from 'lucide-react';
import { useAuth } from './AuthContext';
import { VideoUpload } from './VideoUpload';
import { TranscriptionStatus } from './TranscriptionStatus';
import { TranscriptionHistory } from './TranscriptionHistory';
import { MinutesOverview } from './MinutesOverview';
import { AccountSettings } from './AccountSettings';
import { Footer } from './Footer';
import { supabase } from '../lib/supabase';
import { uploadVideo, createTranscriptionJob, getTranscriptionStatus, TranscriptionError } from '../lib/supabase';
import { convertToSRT } from '../lib/subtitles';
import toast from 'react-hot-toast';

const ITEMS_PER_PAGE = 5;
const STATUS_CHECK_INTERVAL = 2000; // 2 seconds

export const Dashboard: React.FC = () => {
  const { user, signOut } = useAuth();
  const [transcriptions, setTranscriptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [availableMinutes, setAvailableMinutes] = useState(0);
  const [usedMinutes, setUsedMinutes] = useState(0);
  const [transcriptionStatus, setTranscriptionStatus] = useState<'idle' | 'uploading' | 'transcribing' | 'completed' | 'error'>('idle');
  const [progress, setProgress] = useState(0);
  const [transcriptionResult, setTranscriptionResult] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [currentTranscriptionId, setCurrentTranscriptionId] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      loadTranscriptions();
      loadMinutes();
    }
  }, [user]);

  useEffect(() => {
    let intervalId: number | undefined;

    if (currentTranscriptionId && transcriptionStatus === 'transcribing') {
      intervalId = window.setInterval(async () => {
        try {
          const transcription = await getTranscriptionStatus(currentTranscriptionId);
          
          if (transcription.status === 'completed') {
            setTranscriptionStatus('completed');
            setTranscriptionResult(transcription.transcription_text);
            setCurrentTranscriptionId(null);
            loadTranscriptions();
            loadMinutes();
          } else if (transcription.status === 'failed') {
            setTranscriptionStatus('error');
            setErrorMessage(transcription.error_message || 'Transcription failed');
            setCurrentTranscriptionId(null);
          }
        } catch (error) {
          console.error('Error checking transcription status:', error);
          setTranscriptionStatus('error');
          setErrorMessage(error instanceof Error ? error.message : 'Failed to check transcription status');
          setCurrentTranscriptionId(null);
        }
      }, STATUS_CHECK_INTERVAL);
    }

    return () => {
      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };
  }, [currentTranscriptionId, transcriptionStatus]);

  const loadMinutes = async () => {
    try {
      const { data, error } = await supabase
        .from('user_minutes')
        .select('available_minutes, used_minutes')
        .eq('user_id', user?.id)
        .single();

      if (error) throw error;

      if (data) {
        setAvailableMinutes(Number(data.available_minutes));
        setUsedMinutes(Number(data.used_minutes));
      }
    } catch (error) {
      console.error('Error loading minutes:', error);
      toast.error('Failed to load minutes information');
    }
  };

  const loadTranscriptions = async (startIndex = 0) => {
    if (loading) return;

    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('transcriptions')
        .select('*')
        .eq('user_id', user?.id)
        .order('created_at', { ascending: false })
        .range(startIndex, startIndex + ITEMS_PER_PAGE - 1);

      if (error) throw error;

      if (startIndex === 0) {
        setTranscriptions(data);
      } else {
        setTranscriptions(prev => [...prev, ...data]);
      }

      setHasMore(data.length === ITEMS_PER_PAGE);
    } catch (error) {
      toast.error('Failed to load transcriptions');
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    loadTranscriptions(transcriptions.length);
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      window.location.href = '/login';
    } catch (error) {
      toast.error('Failed to sign out');
    }
  };

  const handleVideoSelect = async (fileOrUrl: File | string) => {
    if (availableMinutes <= 0) {
      toast.error('You have no available minutes. Please upgrade your plan.');
      return;
    }

    setTranscriptionStatus('uploading');
    setProgress(0);
    setErrorMessage(undefined);
    setTranscriptionResult(undefined);
    setCurrentTranscriptionId(null);

    try {
      let videoUrl: string;
      let filename: string | undefined;

      if (fileOrUrl instanceof File) {
        const uploadResult = await uploadVideo(fileOrUrl);
        videoUrl = uploadResult.url;
        filename = uploadResult.filename;
        setProgress(50);
      } else {
        videoUrl = fileOrUrl;
        setProgress(50);
      }

      setTranscriptionStatus('transcribing');
      const transcription = await createTranscriptionJob(videoUrl, filename);
      
      if (transcription.status === 'failed') {
        throw new TranscriptionError(transcription.error_message || 'Transcription failed');
      }

      setCurrentTranscriptionId(transcription.id);
    } catch (error) {
      setTranscriptionStatus('error');
      setErrorMessage(error instanceof Error ? error.message : 'An unexpected error occurred');
    }
  };

  const handleRetry = async (transcription: any) => {
    if (availableMinutes <= 0) {
      toast.error('You have no available minutes. Please upgrade your plan.');
      return;
    }

    try {
      const newTranscription = await createTranscriptionJob(
        transcription.video_url,
        transcription.filename
      );
      
      if (newTranscription.status === 'failed') {
        throw new TranscriptionError(newTranscription.error_message || 'Retry failed');
      }

      toast.success('Transcription retry started');
      loadTranscriptions();
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'Failed to retry transcription');
    }
  };

  const handleDownload = (transcription: any, format: 'txt' | 'srt') => {
    if (!transcription.transcription_text) {
      toast.error('No transcription text available');
      return;
    }

    const content = format === 'srt' 
      ? convertToSRT(transcription.transcription_text)
      : transcription.transcription_text;

    const blob = new Blob([content], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `transcription-${transcription.filename || 'output'}.${format}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <div className="flex items-center">
              <Glasses className="h-8 w-8 text-[#81b29a] transform -rotate-12" />
              <span className="ml-2 text-xl font-semibold text-gray-900">Voxi Scribe</span>
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={handleSignOut}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#81b29a]"
              >
                <LogOut className="h-4 w-4 mr-2" />
                Sign out
              </button>
            </div>
          </div>
        </div>
      </nav>

      <main className="flex-grow max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <MinutesOverview
              availableMinutes={availableMinutes}
              usedMinutes={usedMinutes}
            />
            <AccountSettings />
          </div>

          <div className="bg-white rounded-xl shadow-sm p-6 sm:p-8">
            <h2 className="text-xl font-bold text-gray-900 mb-6">
              Upload Media for Transcription
            </h2>
            <VideoUpload onVideoSelect={handleVideoSelect} />
            <TranscriptionStatus
              status={transcriptionStatus}
              progress={progress}
              result={transcriptionResult}
              error={errorMessage}
            />
          </div>

          <div className="bg-white rounded-xl shadow-sm p-6 sm:p-8">
            <h2 className="text-xl font-bold text-gray-900 mb-6">
              Transcription History
            </h2>
            <TranscriptionHistory
              transcriptions={transcriptions}
              onDownload={handleDownload}
              onRetry={handleRetry}
              hasMore={hasMore}
              onLoadMore={loadMore}
              loading={loading}
            />
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};